









































































import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import { getExamination, postSign } from '@/api/myExam/myExam.ts';

@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class MyExam extends Vue {
  // 验证码展示隐藏
  isShow = true
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      semester_id: 0,
      student: {
          stu_id: '',
          phone: ''
      }
  };

  // 验证码
  verifyCode = ''
  code = ''
  num = 1 // 验证码请求参数
  loading = true;
  examType = 0;
  examQuesType = false;
  ExaminationData = [{
      // eslint-disable-next-line @typescript-eslint/camelcase
      crs_id: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      crs_name: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      stu_exam: {},
      // eslint-disable-next-line @typescript-eslint/camelcase
      exm_start: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      exm_end: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      ques_id: 0,
      is_sign: '',
      isShow: false
  }];

  // 当前时间
  nowTime = 0;
  // 考试按钮 状态 样式
  buttonArr = [{
      buttonClass: '',
      buttonMsg: ''
  }];

  created() {
      this.getData();
  }

  // 方法
  getData() {
      const time = new Date();
      this.nowTime = time.getTime();
      // eslint-disable-next-line @typescript-eslint/camelcase
      const checksem = JSON.parse(localStorage.getItem('checkSemester') as string);
      if (checksem !== null) {
          this.data.semester_id = parseInt(checksem.id);
      } else {
          this.data.semester_id = parseInt(localStorage.getItem('semesterId') as string);
      }
      this.data.student = JSON.parse(localStorage.getItem('student') as string);
      this.examType = JSON.parse(localStorage.getItem('student') as string).exam_type;

      getExamination(this.data).then((res: any) => {
          if (res.code === 200) {
              res.data.forEach((item: any, index: number) => {
                  this.examinationState(item, index);
              });
              this.ExaminationData = res.data;
          } else {
              this.$message.error(res.error);
          }
          this.loading = false;
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 当前试卷状态
  examinationState(item: any, index: number) {
      // 转换后台时间
      const startTime = (new Date(item.exm_start)).getTime();
      const endTime = (new Date(item.exm_end)).getTime();
      // 考试状态
      const submitNum = item.stu_exam.submit_num;
      // const is_submit = item.stu_exam.submit_num;
      const isPassed = item.stu_exam.is_passed;

      if (this.nowTime < startTime) {
          this.buttonArr.push({
              buttonClass: 'grey-button',
              buttonMsg: '未开始'
          });
      } else if (this.nowTime > startTime && this.nowTime < endTime) {
          if (submitNum === 0) {
              if (index === 0) {
                  this.buttonArr[0].buttonClass = 'blue-button';
                  this.buttonArr[0].buttonMsg = '去考试';
                  return;
              }
              this.buttonArr.push({
                  buttonClass: 'blue-button',
                  buttonMsg: '去考试'
              });
          } else if (isPassed === 1) {
              if (index === 0) {
                  this.buttonArr[0].buttonClass = 'grey-button';
                  this.buttonArr[0].buttonMsg = '已通过';
                  return;
              }
              this.buttonArr.push({
                  buttonClass: 'grey-button',
                  buttonMsg: '已通过'
              });
          } else if (isPassed === 0 && submitNum !== 0) {
              if (this.examType === 1) {
                  if (index === 0) {
                      this.buttonArr[0].buttonClass = 'grey-button';
                      this.buttonArr[0].buttonMsg = '已完成';
                      return;
                  }
                  this.buttonArr.push({
                      buttonClass: 'grey-button',
                      buttonMsg: '已完成'
                  });
              } else {
                  if (index === 0) {
                      this.buttonArr[0].buttonClass = 'red-button';
                      this.buttonArr[0].buttonMsg = '去补考';
                      return;
                  }
                  this.buttonArr.push({
                      buttonClass: 'red-button',
                      buttonMsg: '去补考'
                  });
              }
          }
      } else if (this.nowTime > endTime) {
          if (index === 0) {
              this.buttonArr[0].buttonClass = 'grey-button';
              this.buttonArr[0].buttonMsg = '已结束';
              return;
          }
          this.buttonArr.push({
              buttonClass: 'grey-button',
              buttonMsg: '已结束'
          });
      }
      this.buttonArr.forEach((item, index) => {
          if (!item.buttonClass) {
              this.buttonArr.splice(index, 1);
          }
      });
  }

  // 渲染时自动调用1转换01
  courseTitleValue(index: number) {
      index++;
      return index >= 10 ? index.toString() : '0' + index;
  }

  goExaminationAnswer(index: number) {
      if (this.buttonArr[index].buttonMsg === '去考试' || this.buttonArr[index].buttonMsg === '去补考') {
          if (!this.ExaminationData[index].is_sign) {
              this.$message.error('请先签到,再去考试!');
              return false;
          }
          this.$router.push({
              path: '/examinationAnswer',
              query: {
                  crsId: this.ExaminationData[index].crs_id.toString(),
                  crsName: this.ExaminationData[index].crs_name,
                  quesId: this.ExaminationData[index].ques_id.toString(),
                  stuExam: JSON.stringify(this.ExaminationData[index].stu_exam)
              }
          });
      } else if (this.buttonArr[index].buttonMsg === '未开始') {
          this.$message.error('考试暂未开始！');
      }
  }

  // 验证码显示隐藏
  showSign(index: number, item: any) {
      console.log(this.BASEURL);
      this.verifyCode = this.BASEURL + '/examination/makecode?phone=' + this.data.student.phone;
      this.code = '';
      // 如果验证码已经显示，则隐藏验证码
      if (this.ExaminationData[index].isShow) {
          this.ExaminationData[index].isShow = false;
          return;
      }
      // 验证码隐藏
      if (item.is_sign === 1) {
          this.ExaminationData[index].isShow = false;
          this.$message.warning('已签到，请勿重复签到');
      } else {
          for (let i = 0; i < this.ExaminationData.length; i++) {
              this.ExaminationData[i].isShow = false;
          }
          this.ExaminationData[index].isShow = true;
      }
  }

  // 改变验证码
  changeCode() {
      this.isShow = false;
      setTimeout(() => {
          this.verifyCode = this.BASEURL + `/examination/makecode?phone=${this.data.student.phone}&num=${this.num}`;
          this.isShow = true;
          this.num++;
      }, 500);
  }

  // 提交验证码
  submint(index: number, item: any) {
      const start = new Date(this.ExaminationData[index].exm_start).getTime();
      const end = new Date(this.ExaminationData[index].exm_end).getTime();
      const now = new Date().getTime();
      if (!(now >= start && now <= end)) {
          this.$message.error('请在考试时间范围内签到!');
          return;
      }
      if (this.code === '' || this.code === null) {
          this.$message.error('请输入验证码!');
          return;
      }
      const data = {
          stu_id: this.data.student.stu_id,
          phone: this.data.student.phone,
          code: this.code,
          semester_id: this.data.semester_id,
          crs_id: item.crs_id
      };
      postSign(data).then((res: any) => {
          if (res.code === 200) {
              this.$message.success(res.msg);
              this.getData();
              this.changeCode();
          } else {
              this.$message.error(res.error);
          }
      }).catch((error: any) => {
          console.log(error);
      });
  }
}
